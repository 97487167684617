<template>
	<div class="container" ref="getheight">
		<div class="page-title-wrap ">
			<div class="page-title">卖家出售列表({{total}}份)</div>
			<img src="../assets/page-bg.png" alt="" class="page-title-bg">
		</div>
		<div class="code-wrap flex-column" v-if="showVerify">
			<div class="code-title">请输入验证码</div>
			<img :src="base64code" @click.stop="getVerifyCode" alt="" class="code-img">
			<div class="code-tips">看不清？点击图片换一张</div>
			<div class="code-input-wrap">
				<input type="number" oninput="if(value.length > 11)value = value.slice(0, 11)" maxlength="11"
					v-model="verifyCode" placeholder="请输入验证码" />
			</div>
			<div class="send-code-btn" @click="getGoods(1)">确定</div>
		</div>
		<div class="verify-model" v-if="showVerify" @click.stop="closeVerifyModal()"></div>
		<van-list v-model="loading" @load="getGoods" :finished="finished" finished-text=" " :error.sync="error"
			error-text="请求失败，点击重新加载">

			<div class="sale-list flex-column">
				<div class="sale-item flex-column" v-for="(item,index) in list" v-bind:key="index"
					@click="navDetail(index,item.id)">
					<div class="sale-item-top">
						<div class="sale-item-row flex-row align-center space-between">
							<div class="sale-goods-name">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
							<div class="sale-goods-price">售卖价：￥{{item.salePrice}}</div>
						</div>
						<div class="flex-row align-center">
							<div class="sale-item-no">{{item.periodView?item.periodView:item.position}}</div>
							<div class="goods-balance-wrap flex-row align-center ml10" v-if="item.income !=null">
								<div class="goods-balance-wrap-ti">余额</div>
								<div class="goods-balance-wrap-con">{{item.income}}</div>
							</div>
							<div class="goods-balance-wrap flex-row align-center ml10"
								v-if="item.tkOperationsWithdrawAmount !=null &&  item.tkOperationsWithdrawAmount >0">
								<div class="goods-balance-wrap-ti">余额</div>
								<div class="goods-balance-wrap-con">{{item.tkOperationsWithdrawAmount}}</div>
							</div>
							<div class="goods-upt-wrap flex-row align-center ml10" v-if="item.surplusPoints !=null">
								<div class="goods-upt-wrap-ti">未发放</div>
								<div class="goods-upt-wrap-con">{{item.surplusPoints}}</div>
							</div>
							<div class="goods-pt-wrap flex-row align-center ml10" v-if="item.havePoints !=null">
								<div class="goods-pt-wrap-ti">积分</div>
								<div class="goods-pt-wrap-con">{{item.havePoints}}</div>
							</div>
						</div>
					</div>
					<div class="sale-item-bottom flex-row align-center space-between"
						v-if="item.status == 2">
						<div class="sale-item-l-btn-wrap flex-row align-center justify-center">
							<div class="sale-item-l-btn-wrap-text">锁定中</div>
							<img src="../assets/prolist/lock.png" class="sale-item-icon" alt="">
						</div>
						<div class="sale-item-r-btn-wrap flex-row align-center justify-center">
							<div class="sale-item-r-btn-wrap-text">去购买</div>
							<img src="../assets/prolist/more.png" class="sale-item-icon2" alt="">
						</div>
					</div>
					<div class="sale-item-bottom flex-row align-center space-between"
						v-if="item.status == 0">
						<div class="sale-item-l-btn-wrap2 flex-row align-center justify-center">
							<div class="sale-item-l-btn-wrap-text2">出售中</div>
							<img src="../assets/prolist/sale.png" class="sale-item-icon3" alt="">
						</div>
						<div class="sale-item-r-btn-wrap2 flex-row align-center justify-center" >
							<div class="sale-item-r-btn-wrap-text">去购买</div>
							<img src="../assets/prolist/more.png" class="sale-item-icon2" alt="">
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="good-list flex-row align-center flex-wrap space-between">
				<div class="good-item-new flex-column" v-for="(item,index) in list" v-bind:key="index"
					@click="navDetail(index,item.id)">
					<div class="relative">
						<div v-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period !=8"
							class="goods-item-wrap" :style="'background-image: url('+item.pic+');'">
						</div>
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 2 && item.period == 8"
							src="https://ipfs.allnfts.cn/ipfs/QmY95PAi4TuXWeh2vdt3XcpSrn4M8LQDeeixULxLsmg6Fa"
							class="good-item-img3" />
						<img v-else-if="item.isOpen == '是' &&  item.consignmentType == 1" :src="item.pic"
							class="good-item-img3" />
						<img v-else src="../assets/manghe.png" class="good-item-img2" />
					</div>
					<div class="good-item-con">
						<div class="goods-name-wrap flex-row align-center space-between"
							v-if="item.userCollectCount !=null">
							<div class="good-item-name2">{{item.isOpen == '是'?item.name:'盲盒'}}</div>
							<div class="collect-wrap flex-row align-center">
								<img src="../assets/shop/icon-star.png" class="icon-collect" alt="">
								<div class="collect-num">{{item.userCollectCount}}</div>
							</div>
						</div>
						<div class="good-item-name" v-else>{{item.isOpen == '是'?item.name:'盲盒'}}</div>
						<div class="flex-row align-center space-between goods-mt" v-if="item.mergeRecordCount>0">
							<div class="good-item-num2">
								{{item.periodView?item.periodView:item.position}}
							</div>
							<div class="compound-num-wrap flex-row align-center">
								<div class="compound-num-wrap-ti">实际数量</div>
								<div class="compound-num-wrap-con">{{item.mergeRecordCount}}</div>
							</div>
						</div>
						<div class="good-item-num" v-else>
							{{item.periodView?item.periodView:item.position}}
						</div>
						<div class="flex-row align-center space-between goods-mt" v-if="item.income !=null">
							<div class="good-item-price2">￥{{item.salePrice}}</div>
							<div class="goods-balance-wrap flex-row align-center">
								<div class="goods-balance-wrap-ti">余额</div>
								<div class="goods-balance-wrap-con">{{item.income}}</div>
							</div>
						</div>
						<div class="good-item-price" v-else>￥{{item.salePrice}}</div>
						<div class="flex-row align-center space-between pt-wrap"
							v-if="item.havePoints !=null || item.surplusPoints !=null || (item.tkOperationsWithdrawAmount !=null &&  item.tkOperationsWithdrawAmount >0)">
							<div></div>
							<div class="for-goods-pt flex-row align-center">
								<div class="goods-balance-wrap flex-row align-center"
									v-if="item.tkOperationsWithdrawAmount !=null &&  item.tkOperationsWithdrawAmount >0">
									<div class="goods-balance-wrap-ti">余额</div>
									<div class="goods-balance-wrap-con">{{item.tkOperationsWithdrawAmount}}</div>
								</div>
								<div class="goods-upt-wrap flex-row align-center" v-if="item.surplusPoints !=null">
									<div class="goods-upt-wrap-ti">未发放</div>
									<div class="goods-upt-wrap-con">{{item.surplusPoints}}</div>
								</div>
								<div class="goods-pt-wrap flex-row align-center" v-if="item.havePoints !=null">
									<div class="goods-pt-wrap-ti">积分</div>
									<div class="goods-pt-wrap-con">{{item.havePoints}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</van-list>

		<div class="empty flex-column align-center justify-center" v-if="empty">
			<div class="empty-tip">无卖家出售</div>
		</div>
		
		<div class="pl-btn-wrap flex-row align-center justify-center">
			<div class="pl-btn" @click="batchBuy">批量购买</div>
		</div>
		
		
		<div class="model" v-if="confirmModal" @click.stop="closeBuyModal"></div>
		<div class="buy-confirm-wrap" v-if="confirmModal">
			<div class="bc-top flex-row align-center">
				<div class="bc-ti">选择购买数量</div>
				<div class="close-bc" @click.stop="closeBuyModal">关闭</div>
			</div>
			<div class="flex-row align-center tips-wrap">
				<img src="../assets/icon-tips.png" class="icon-tips" alt="">
				<div class="tips">批量购买需要消耗88积分</div>
			</div>
			<div class="num-con flex-row align-center justify-center">
				<div class="add" @click.stop="reduce()">-</div>
				<input type="text" onkeyup="value=value.replace(/[^\d]/g,'')"
					oninput="if(value.length > 2)value = value.slice(0,2)" class="num" v-model="num">
				<div class="reduce" @click.stop="add()">+</div>
			</div>
		
			<div class="confirm-btn" @click.stop="confirmBuy()">确认</div>
		</div>
	</div>
</template>

<script>
	import util from '../config/util.js';
	import config from '../config/config.js';
	import wx from 'weixin-js-sdk';
	import {
		Toast
	} from 'vant';
	import 'vant/lib/toast/index.css'
	import dataCenterBus from '../config/dataCenterBus.js';
	export default {
		data() {
			return {
				name: '',
				period: '',
				isOpen: '',
				consignmentType: '',
				list: [],
				empty: 0,
				more: true,
				pageNum: 1,
				pageSize: 10,
				windowHeight: 0,
				offsetHeight: 0,
				loading: false,
				finished: false,
				error: false,
				showVerify: 0,
				base64code: '',
				verifyKey: '',
				verifyCode: '',
				total: 0,
				confirmModal: false,
				num: 5,
				consignmentId:''
			}
		},
		methods: {
			confirmBuy() {
				const that = this;
				let params = new FormData()
				params.append('buyQuantity', that.num)
				params.append('consignmentId', that.consignmentId)
				this.axios.post(config.requestUrl + '/front/order/batchCreateOrderByConsignment', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$router.push({
							path: '/pay',
							query: {
								type: 2,
								order_id: response.data.obj.id,
								name: that.goods.name,
								productPic: that.goods.isOpen == '是' ? that.goods.pic :
									'../assets/manghe.png',
								payAmount: response.data.obj.payAmount,
								salePrice: response.data.obj.payAmount,
								isOpen: that.goods.isOpen
							}
						})
					} else {
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			
			
				// let params = new FormData()
				// params.append('orderId', 58750)
				// this.axios.post(config.requestUrl + '/front/order/queryOrderStatus', params, {
				// 	headers: {
				// 		'Content-Type': 'multipart/form-data',
				// 		'token': this.$store.state.user.token
				// 	}
				// }).then(response => {
				// 	if (response.data.code == 0) {
				// 		console.log(response)
				// 	} else {
				// 		Toast(response.data.msg);
				// 	}
				// }, response => {
				// 	Toast('获取失败，请稍后重试');
				// })
			
			},
			closeBuyModal() {
				this.confirmModal = 0
			},
			add() {
				if (Math.abs(parseInt(this.num)) < 10) this.num = Math.abs(parseInt(this.num)) + 1
			},
			reduce() {
				if (Math.abs(parseInt(this.num)) > 1) this.num = Math.abs(parseInt(this.num)) - 1
			},
			batchBuy() {
				if (!this.$store.state.user.token) {
					this.login();
				} else {
					this.confirmModal = true;
				}
			},
			
			onScroll(e) {
				let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body
					.scrollTop; //页面滚动高度
				if (this.offsetHeight < scroll + this.windowHeight + 50) {
					if (this.more && !this.loading) {
						this.getGoods();
					}
				}
			},
			getGoods(verify) {
				const that = this;
				if (!this.$store.state.user.token) {
					this.$router.replace('/')
					return;
				}
				let params = {
					fullName: this.name,
					period: this.period,
					consignmentType: this.consignmentType,
					isOpen: this.isOpen,
					likeName: this.keywords,
					sortBy: 'sale_price',
					order: 'asc',
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}
				if (verify) {
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
				}
				this.axios.get(config.requestUrl + '/front/consignment/oldListConsignment', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list
						let list = that.list;
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						list = list.concat(newList);
					
						
						if (list.length == 0) that.empty = true;
						that.list = list;
						that.pageNum = that.pageNum + 1;
						that.finished = finished;
						that.showVerify = false;
						that.error = false;
						that.total= response.data.obj.total
						console.log(response)
					} else if (response.data.code == -7) {
						that.error = true;
						that.getVerifyCode()
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
						that.error = true;
					}
					this.loading = false;
				}, response => {
					Toast('获取失败，请稍后重试');
					that.error = true;
					this.loading = false;
				})
			},
			navDetail(index, id) {
				let item = this.list[index]
				if(item.status !=0) return;
				this.$router.push({
					path: '/pay',
					query: {
						type: 1,
						id: id,
						name: item.name,
						productPic: item.isOpen == '是' ? item.pic : '../assets/manghe.png',
						productEntryNo: item.isOpen == '是' ? item.productEntryNo : item.periodView,
						salePrice: item.salePrice,
						isOpen: item.isOpen
					}
				})
			},
			getVerifyCode() {
				const that = this;
				let params = new FormData()
				this.axios.post(config.requestUrl + '/search/getImage', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.showVerify = 1;
						that.verifyKey = response.data.obj.key;
						that.base64code = response.data.obj.data
						console.log(response)
					} else {
						Toast.fail(response.data.msg);
					}
				}, response => {
					let msg = response.data.msg;
					const str = "未获取到用户信息";
					const str2 = "登录已失效"
					if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
						localStorage.removeItem("user");
						that.$store.commit("clearUser");
						that.$store.commit("clearCode");
					}
					Toast.fail(response.data.msg);
				})
			},
			closeVerifyModal() {
				this.showVerify = 0;
			},
			refreshData(verify) {
				const that = this;
				if (!this.$store.state.user.token) {
					this.$router.replace('/')
					return;
				}
				let pageNum = this.pageNum > 5 ? 5 : this.pageNum
				let params = {
					fullName: this.name,
					period: this.period,
					consignmentType: this.consignmentType,
					isOpen: this.isOpen,
					sortBy: 'sale_price',
					order: 'asc',
					pageNum: 1,
					pageSize: pageNum * this.pageSize
				}
				if (verify) {
					params.codeValue = that.verifyCode;
					params.key = that.verifyKey;
				}
				this.axios.get(config.requestUrl + '/front/consignment/oldListConsignment', {
					params,
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': this.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						Toast.clear();
						let newList = response.data.obj.list
						let finished = false;
						if (response.data.obj.isLastPage) finished = true;
						if (newList.length == 0) this.empty = true;
						this.list = newList;
						that.finished = finished;
					} else {
						let msg = response.data.msg;
						const str = "未获取到用户信息";
						const str2 = "登录已失效"
						if (msg.indexOf(str) != -1 || msg.indexOf(str2) != -1) {
							localStorage.removeItem("user");
							that.$store.commit("clearUser");
							that.$store.commit("clearCode");
							setTimeout(() => {
								this.$router.push('/')
							}, 1200)
						}
						Toast(response.data.msg);
					}
				}, response => {
					Toast('获取失败，请稍后重试');
				})
			},
		},
		mounted() {
			this.name = this.$route.query.name;
			this.period = this.$route.query.period;
			this.isOpen = this.$route.query.isOpen;
			this.consignmentType = this.$route.query.consignmentType;
			this.consignmentId=this.$route.query.consignmentId;
			util.wxConfig();
			dataCenterBus.$on('refreshData', () => {
				console.log('asd')
				this.refreshData();
			});
			console.log('mouted')
		}
	}
</script>

<style scoped="scoped">
	.container {
		/* width: 100%;
		min-height: 100%;
		background: #f2f2f2;
		width: 100%;
	
		min-height: 100%;
		overflow: visible; */
		width: 100%;
		background: #000000;
		min-height: 100%;
		padding-bottom: 180px;
	}

	.empty {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
	}

	.empty-tip {
		font-size: 40px;
		color: #fff;
	}

	/* 蒙层 */
	.verify-model {
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		z-index: 1001;
	}

	.code-wrap {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 1002;
		background: #FFFFFF;
		width: 500px;
		height: max-content;
		border-radius: 10px;
		padding: 40px 30px;
		box-sizing: border-box;
	}

	.code-title {
		font-size: 30px;
		font-weight: bold;
		text-align: center;
	}

	.code-img {
		width: 400px;
		height: 200px;
		display: flex;
		margin: 20px auto 15px;
	}

	.code-tips {
		width: 100%;
		text-align: center;
		font-size: 22px;
		color: #999999;
	}

	.code-input-wrap {
		width: 440px;
		height: 100px;
		border-radius: 60px;
		border: 1px solid #EEEEEE;
		font-size: 28px;
		color: #000000;
		line-height: 40px;
		letter-spacing: 1px;
		padding-left: 30px;
		box-sizing: border-box;
		margin-top: 30px;
	}

	.code-input-wrap input {
		width: 380px;
		height: 94px;
		font-size: 26px;
		color: #000000;
		line-height: 40px;
		padding: 0 30px;
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
		border: none;
		box-sizing: border-box;
	}

	.send-code-btn {
		margin-top: 80px;
		width: 440px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		font-size: 30px;
		color: #FFFFFF;
		letter-spacing: 2px;
		text-align: center;
		line-height: 80px;
	}

	.sale-item {
		width: 720px;
		height: 225px;
		background: #FFFFFF;
		border-radius: 30px;
		margin: 15px auto 0;
		overflow: hidden;
	}

	.sale-item-top {
		width: 100%;
		height: 150px;
		padding: 0 30px;
		box-sizing: border-box;
	}

	.sale-item-row {
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.sale-goods-name {
		font-weight: 600;
		color: #000000;
		font-size: 30px;
	}

	.sale-goods-price {
		font-weight: 600;
		color: #000000;
		font-size: 28px;
	}

	.sale-item-no {
		font-size: 26px;
		font-weight: 400;
		color: #6D7278;
	}

	.sale-item-bottom {
		width: 100%;
		height: 75px;
		background: #F4F4F4;
		padding: 0 30px;
		box-sizing: border-box;
	}

	.sale-item-l-btn-wrap {
		width: 145px;
		height: 40px;
		background: #FFF1CA;
		border-radius: 20px;
	}

	.sale-item-l-btn-wrap-text {
		font-size: 24px;
		font-weight: 500;
		color: #FA6400;
		margin-right: 10px;
	}

	.sale-item-icon {
		width: 18px;
		height: 23px;
	}

	.sale-item-r-btn-wrap {
		width: 150px;
		height: 50px;
		background: #A9A9A9;
		border-radius: 25px;
	}

	.sale-item-r-btn-wrap-text {
		font-size: 26px;
		font-weight: 500;
		color: #FFFFFF;
		margin-right: 8px;
	}

	.sale-item-icon2 {
		width: 21px;
		height: 17px;
	}

	.sale-item-l-btn-wrap2 {
		width: 145px;
		height: 40px;
		background: #D9ECFF;
		border-radius: 20px;
	}

	.sale-item-l-btn-wrap-text2 {
		font-size: 24px;
		font-weight: 500;
		color: rgba(0, 0, 0, 0.67);
	}

	.sale-item-icon3 {
		width: 21px;
		height: 21px;
		margin-left: 10px;
	}

	.sale-item-r-btn-wrap2 {
		width: 150px;
		height: 50px;
		border-radius: 25px;
		background: linear-gradient(138deg, #9B66FF 0%, #6236FF 100%);
	}
	.ml10{
		margin-left: 10px;
	}
	
	.pl-btn-wrap{
		width: 750px;
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px -8px 8px 0px rgba(0,0,0,0.04);
		position: fixed;
		left: 0;
		bottom: 0;
	}
	.pl-btn{
		width: 250px;
		height: 66px;
		background: #000000;
		border-radius: 33px;
		font-size: 28px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 66px;
		text-align: center;
	}
	
	.buy-confirm-wrap {
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1001;
		background: #FFFFFF;
		padding-bottom: 18px;
	}
	
	.bc-top {
		width: 750px;
		height: 88px;
		background: #FFFFFF;
		border-radius: 20px 20px 0px 0px;
		position: relative;
	}
	
	.bc-ti {
		width: 750px;
		text-align: center;
	}
	
	.close-bc {
		position: absolute;
		top: 30px;
		right: 34px;
		font-size: 28px;
		font-weight: 400;
		color: #000000;
	}
	
	.tips-wrap {
		width: 750px;
		background: rgba(247, 181, 0, 0.1);
		border-radius: 0px 0px 20px 20px;
		padding: 20px 40px;
		box-sizing: border-box;
	}
	
	.icon-tips {
		width: 21px;
		height: 24px;
		margin-right: 9px;
	}
	
	.tips {
		font-size: 24px;
		font-weight: 600;
		color: #FA6400;
	}
	
	.addr-wrap {
		width: 388px;
		height: 80px;
		margin: 60px auto 89px;
		background: rgba(201, 201, 201, 0.1);
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	
	.addr-input {
		width: 318px;
		height: 80px;
		background: rgba(201, 201, 201, 0.1);
		border: none;
		font-size: 28px;
		font-weight: 400;
		padding: 0 18px;
		box-sizing: border-box;
	}
	
	.ques-img {
		width: 32px;
		height: 32px;
		margin-left: 21px;
	}
	
	.num-con {
		margin: 60px auto 0
	}
	
	.reduce {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}
	
	.num {
		width: 130px;
		height: 80px;
		background: rgba(0, 0, 0, 0.1);
		text-align: center;
		border: none;
	}
	
	.add {
		width: 130px;
		height: 80px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px 0px 0px 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		line-height: 80px;
		text-align: center;
		color: #000000;
		font-size: 35px;
		font-weight: bolder;
	}
	
	.confirm-btn {
		width: 590px;
		height: 80px;
		margin: auto;
		background: linear-gradient(138deg, #9764ff 0%, #6539ff 100%);
		border-radius: 40px;
		text-align: center;
		font-size: 32px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 80px;
		margin-top: 80px
	}
</style>